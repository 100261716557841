/*
  Project: Stockroom London
  Author: Via Studios
 */

let timer;
let arrCount = 0;
const bubble = document.querySelector('.c-animation__pickup');
const timerArr = [2100, 3000, 2100, 4000, 2100, 4000, 3000];
const pickupArr = [
  'Store: 10 Desks/Chairs',
  'Database: 250 archive boxes',
  'Retrieval: 20 Master tapes',
  'Backup: 15 HD drive - LTO',
  'Clients: 3 welcome tours',
  'Deliver: 100 archive boxes',
  'Deliver: 10 TV costumes',
];

function pickup() {
  // Set the pickup, display the bubble
  bubble.innerHTML = pickupArr[arrCount];
  bubble.classList.add('animating');

  // Remove the bubble after 2 secs (animation length)
  const clearAnimation = setTimeout(() => {
    bubble.classList.remove('animating');
    clearTimeout(clearAnimation);
  }, 2000);

  // Move onto the next pickup
  if (arrCount + 1 >= pickupArr.length) {
    arrCount = 0;
  } else {
    arrCount += 1;
  }

  // Call the next pickup
  clearInterval(timer);
  timer = setInterval(pickup, timerArr[arrCount]);
}

document.addEventListener('DOMContentLoaded', () => {
  const toggleButton = document.querySelector('.c-main-nav__toggle');
  const navList = document.getElementById('main-nav-list');

  toggleButton.addEventListener('click', () => {
    const isExpanded = toggleButton.getAttribute('aria-expanded') === 'true';
    toggleButton.setAttribute('aria-expanded', !isExpanded);
    navList.classList.toggle('c-main-nav--open');
  });

  if (bubble && pickupArr.length > 0) {
    timer = setInterval(pickup, timerArr[arrCount]);
  }
});
